:root {
  --font-base: "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium",
    "Yu Gothic Medium", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-seif;
}

body {
  font-family: "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium",
    "Yu Gothic Medium", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-seif;
  font-size: 16px;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  font-size: 1em;
  line-height: 1em;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  margin: 0;
  line-height: 1em;
}
a {
  color: #000;
  line-height: 1em;
  text-decoration: none;
}
img {
  vertical-align: top;
}
input[type="text"] {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}
input[type="text"] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  max-width: 100%;
}
