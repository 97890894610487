@import "css/sanitize.css";
@import "css/base.css";

/* override material-ui style */
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16.MuiPaper-root {
  top: 0px;
  width: 85%;
  color: #fff;
  background-color: #181b1b
}

@media screen and (min-width: 768px) {
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16.MuiPaper-root {
    width: 400px
  }
}

/* define main contents width */
@media screen and (min-width: 768px) {
  #contents {
    max-width: 1800px;
    width: 100%;
    margin: 0 auto;
  }
}
